import { h, render } from 'preact';
import Episodes from './../../components/Episodes/Episode';
import Player from '../../components/Player/Player';
import { mostRecentStore } from '../../mostRecentStore';
import MostRecent from '../../components/MostRecent/MostRecent';
import Clipboard from 'clipboard';
import PopularPodcasts from '../../components/PopularPodcasts';
import { getProgress, player as s } from '../../player/store';

function Episode(/** match */) {
  /* Player in overlay view / BIG */
  window.addEventListener(
    'player:big.enter',
    () => {
      if (!document.documentElement.classList.contains('player--is-big')) {
        document.documentElement.className += ' player--is-big';
      }
    },
    false
  );
  window.addEventListener(
    'player:big.leave',
    () => {
      if (document.documentElement.classList.contains('player--is-big')) {
        document.documentElement.classList.remove('player--is-big');
      }
    },
    false
  );
  const shareButton = document.querySelector('.podcast-share');
  const shareBoard = document.querySelector('#share_pod');
  const closeShareButton = shareBoard.querySelector('.close-share');
  const clipboard = new Clipboard('#copy_url');
  clipboard.on('success', () => {
    const copyButton = shareBoard.querySelector('#copy_url > span');
    const rawText = copyButton.innerText;
    copyButton.innerText = 'Kopierad!';
    setTimeout(() => {
      shareBoard.classList.add('hidden');
      copyButton.innerText = rawText;
    }, 400);
  });

  closeShareButton.addEventListener('click', (e) => {
    e.stopPropagation();
    shareBoard.classList.add('hidden');
  });

  shareButton.addEventListener('click', (e) => {
    e.stopPropagation();
    shareBoard.classList.remove('hidden');
  });

  document.addEventListener('click', (e) => {
    if (shareBoard.classList.contains('hidden')) {
      return;
    }

    if (!shareBoard.contains(e.target)) {
      shareBoard.classList.add('hidden');
    }
  });

  if (typeof window.__INITIAL_STATE === 'undefined') {
    window.__INITIAL_STATE = {};
  }

  if (window.__INITIAL_STATE.notfound) return;

  const podcast = window.__INITIAL_STATE.podcast;
  const episode = window.__INITIAL_STATE.episode;

  mostRecentStore.load().trackVisit(podcast.itunes_id);

  const listenButton = document.querySelector('button.js-podcast_listen');
  if (listenButton) {
    listenButton.addEventListener('click', (e) => {
      e.preventDefault();
      if (window.__INITIAL_STATE.episode && !s.data.playing.value) {
        s.play(
          episode,
          podcast,
          getProgress(episode, podcast.itunes_id).currentSeek
        );
        e.preventDefault();
      }
    });
  }

  const mountPlayerNode = document.getElementById('playerplace');
  if (mountPlayerNode) {
    s.mount(
      episode,
      podcast,
      getProgress(episode, podcast.itunes_id).currentSeek
    );
    render(h(Player, { store: s }), mountPlayerNode);
  }

  const episodesNode = document.querySelector('.episodes');
  if (episodesNode && episodesNode.parentElement) {
    episodesNode.innerHTML = '';
    render(
      h(Episodes, {
        store: s,
        podcast,
        title: podcast.title,
        header: 'Fler avsnitt av',
        podcastUrl: window.__INITIAL_STATE.podcastUrl,
      }),
      episodesNode.parentElement
    );
  }
  const popularPodcastsNode = document.querySelector('#popularpodcast');
  if (popularPodcastsNode) {
    render(
      h(PopularPodcasts, {
        title: 'Populärt på poddtoppen just nu',
        action: '/populara',
      }),
      popularPodcastsNode
    );
  }

  const mostRecentNode = document.querySelector('#mostrecent');
  if (mostRecentNode) {
    render(
      h(MostRecent, {
        title: 'Senast besökta',
      }),
      mostRecentNode
    );
  }
}

Episode();
